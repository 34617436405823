import { graphql, useStaticQuery } from 'gatsby';

import type { ContentfulCommonCollection } from 'graphqlTypes';

const useAltForms = () => {
  const { contentfulCommonCollection } = useStaticQuery<{
    contentfulCommonCollection: ContentfulCommonCollection;
  }>(graphql`
    query CommonCollectionQuery {
      contentfulCommonCollection(contentful_id: { eq: "7rVeTf9xjWdcFNiy9sJFSs" }) {
        ...contentfulCommonCollection
      }
    }
  `);

  return contentfulCommonCollection?.components || [];
};

export default useAltForms;
