import Icon from 'molecules/Icon';

import type { FC } from 'react';

import 'components/MediaAsset/PlayButton/styles.css';

type PlayButtonProps = {
  handleClick?: () => void;
  isPlaying?: boolean;
};

const PlayButton: FC<PlayButtonProps> = ({ handleClick, isPlaying }) => (
  <div
    className={`wistia-video--play-button ${isPlaying ? 'hide' : 'show'}`}
    aria-label="Play/Pause Button"
    onClick={handleClick}
    id="play-button"
    role="presentation"
  >
    <span className="wistia-video--play-icon">
      <Icon icon={isPlaying ? 'icon-pause' : 'icon-play'} size={24} />
    </span>
  </div>
);

export default PlayButton;
