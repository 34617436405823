/* eslint-disable no-inline-styles/no-inline-styles */
/* eslint-disable tailwindcss/no-custom-classname */
import type { FC } from 'react';

interface HubspotCtaProps {
  hubspotCtaId: string;
}

const HubspotCta: FC<HubspotCtaProps> = ({ hubspotCtaId }) => (
  <span className="hs-cta-wrapper" id={`hs-cta-wrapper-${hubspotCtaId}`}>
    <span className={`hs-cta-node hs-cta-${hubspotCtaId}`} id={`hs-cta-${hubspotCtaId}`}>
      <div id="hs-cta-ie-element"></div>
      <a href={`https://cta-redirect.hubspot.com/cta/redirect/3381425/${hubspotCtaId}`}>
        <img
          className="hs-cta-img"
          id="hs-cta-img-${}"
          style={{ borderWidth: '0px' }}
          src={`https://no-cache.hubspot.com/cta/default/3381425/${hubspotCtaId}.png`}
          alt="Download the full growth report"
        />
      </a>
    </span>
  </span>
);

export default HubspotCta;
