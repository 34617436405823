/* eslint-disable tailwindcss/no-arbitrary-value */
import { cva } from 'class-variance-authority';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import Radio from 'molecules/Radio';

import type { ChangeEventHandler, FC, SyntheticEvent } from 'react';

const helper = cva(['text-gray-600', 'text-xs', 'mt-1'], {
  variants: {
    error: {
      true: ['text-error-700', 'font-semibold'],
    },
  },
});

interface RadioGroupProps {
  name?: string;
  className?: string;
  label?: string;
  defaultValue?: string;
  helperText?: string | false;
  error?: boolean;
  errorMessage?: string;
  options?: { label: string; value: string; description: string }[];
  disabled?: boolean;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
}

const RadioGroup: FC<RadioGroupProps> = ({
  name,
  className,
  label,
  options,
  helperText,
  error,
  errorMessage,
  value,
  defaultValue,
  disabled,
  onChange,
}) => {
  const [currValue, setCurrValue] = useState<string>(defaultValue || '');

  useEffect(() => {
    if (value) {
      setCurrValue(value);
    }
  }, [value]);

  const handleChange = (e: SyntheticEvent) => {
    setCurrValue(e.target.value);
    onChange && onChange(e);
  };

  return (
    <div className={twMerge('w-full mb-4 flex flex-col justify-between gap-4', className)}>
      <div className="flex flex-col">
        {label && (
          <label htmlFor={name} className="text-sm font-semibold text-gray-700">
            {label}
          </label>
        )}
      </div>
      <div className="flex-col">
        <div className="input relative flex flex-row gap-4">
          {options?.map((option, index) => (
            <Radio
              key={`radiogroup-options-${index + 1}`}
              name={name}
              checked={option.value === currValue}
              label={option.label}
              value={option.value}
              handleClick={handleChange}
              helperText={option?.description}
              disabled={disabled}
            />
          ))}
        </div>
        {(helperText || error) && (
          <p className={twMerge(helper({ error }))}>{error ? errorMessage || helperText : helperText}</p>
        )}
      </div>
    </div>
  );
};
export default RadioGroup;
