import { cva } from 'class-variance-authority';

const richTextContent = cva(['mb-4', 'last:mb-0'], {
  variants: {
    theme: {
      light: ['text-gray-900'],
      dark: ['text-gray-200'],
    },
  },
});

export const listStyles = cva(['flex', 'w-full', 'flex-col', 'text-md', 'my-4', 'rt-list']);

export const listItemStyles = cva(['flex', 'gap-x-3']);

export const listCheckStyles = cva(['mt-[3px]', 'min-h-[20px]', 'min-w-[20px]']);

export const linkStyles = cva(['text-green-600', 'underline']);

export const headingStyles = cva(['font-bold', 'text-gray-900'], {
  variants: {
    type: {
      h1: ['text-rtc-h1', 'mb-4'],
      h2: ['text-rtc-h2', 'mb-4'],
      h3: ['text-rtc-h3', 'mb-4'],
      h4: ['text-rtc-h4', 'mb-4'],
      h5: ['text-rtc-h5', 'mb-4'],
      h6: ['text-rtc-h6', 'mb-4'],
    },
    theme: {
      light: ['text-gray-900'],
      dark: ['text-gray-200'],
    },
  },
});

export default richTextContent;
