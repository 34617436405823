import { cva } from 'class-variance-authority';

const formStyles = cva(['flex', 'flex-col', 'gap-4', 'w-full', 'max-w-full', 'h-fit']);

export const headingContent = cva(['font-extrabold', 'text-display-xs-tablet', 'lg:text-display-xs', 'text-gray-900']);

export const headingSubhead = cva(['font-normal', 'text-gray-700']);

export const thankyouMessage = cva(['submitted-message', 'font-normal', 'text-md', 'w-full', 'center']);

export const hubspotFormStyles = cva(['hubspot-form-container', 'w-full'], {
  variants: {
    isSubscribe: {
      true: ['subscribe-form'],
      false: [],
    },
    isInline: {
      true: ['inline-form'],
      false: [],
    },
    hidden: {
      true: ['hidden'],
      false: [],
    },
    mainDemoForm: {
      true: ['main-demo-form'],
      false: [],
    },
  },
});

export default formStyles;
