import { graphql, useStaticQuery } from 'gatsby';

const useHubspotFormData = () => {
  const { allHubSpotForm } = useStaticQuery(graphql`
    query useHubspotFormData {
      allHubSpotForm {
        edges {
          node {
            portalId
            guid
            name
            action
            method
            cssClass
            redirect
            submitText
            followUpId
            notifyRecipients
            leadNurturingCampaignId
            formFieldGroups {
              default
              isSmartGroup
              isPageBreak
              richText {
                content
                type
              }
              fields {
                defaultValue
                description
                displayOrder
                enabled
                fieldType
                groupName
                hidden
                isSmartField
                label
                labelHidden
                name
                objectTypeId
                placeholder
                propertyObjectType
                required
                selectedOptions
                type
                unselectedLabel
                validation {
                  data
                  name
                  useDefaultBlockList
                  message
                }
                options {
                  displayOrder
                  description
                  doubleData
                  hidden
                  label
                  readOnly
                  value
                }
                metaData {
                  name
                  value
                }
                dependentFieldFilters {
                  dependentFormField {
                    name
                    defaultValue
                    description
                    fieldType
                    groupName
                    enabled
                    displayOrder
                    hidden
                    isSmartField
                    label
                    labelHidden
                    objectTypeId
                    placeholder
                    propertyObjectType
                    required
                    type
                    unselectedLabel
                    options {
                      description
                      displayOrder
                      doubleData
                      hidden
                      label
                      readOnly
                      value
                    }
                    validation {
                      data
                      message
                      useDefaultBlockList
                      name
                    }
                  }
                  filters {
                    boolValue
                    numberValue
                    operator
                    strValue
                    strValues
                  }
                  formFieldAction
                }
              }
            }
            createdAt
            updatedAt
            performableHtml
            migratedFrom
            ignoreCurrentValues
            metaData {
              name
              value
            }
            deletable
            inlineMessage
            tmsId
            campaignGuid
            cloneable
            editable
            formType
            deletedAt
            themeName
            parentId
            style
            isPublished
            publishAt
            unpublishAt
            publishedAt
            customUid
            createMarketableContact
            editVersion
            thankYouMessageJson
            themeColor
            alwaysCreateNewCompany
            internalUpdatedAt
            businessUnitId
            portableKey
            embedVersion
          }
        }
      }
    }
  `);

  return allHubSpotForm.edges.map(x => x.node);
};

export default useHubspotFormData;
