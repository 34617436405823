const colors = {
  current: 'currentColor',
  transparent: 'transparent',
  black: '#000000',
  white: '#ffffff',
  gray: {
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#EAECF0',
    300: '#D0D5DD',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#101828',
  },
  primary: {
    25: '#F4FBF8',
    50: '#DCF1EB',
    100: '#95C8CC',
    200: '#61ADB3',
    300: '#2C9199',
    400: '#007C80',
    500: '#006D68',
    600: '#00685E',
    700: '#004E46',
    800: '#00342F',
    900: '#001A18',
  },
  yellow: {
    25: '#FEFBF4',
    50: '#FFECB3',
    100: '#FFDF80',
    200: '#FFD24D',
    300: '#FFCB33',
    400: '#FFBE00',
    500: '#E6AB00',
    600: '#B38500',
    700: '#805F00',
    800: '#4C3900',
    900: '#191300',
  },
  green: {
    25: '#F2F9E6',
    50: '#D7EDB3',
    100: '#BCE180',
    200: '#94CF33',
    300: '#79C300',
    400: '#43B02A',
    500: '#009739',
    600: '#007A15',
    700: '#006211',
    800: '#00490d',
    900: '#002506',
  },
  blue: {
    25: '#FAFCFE',
    50: '#EEF3F9',
    100: '#DEE8F4',
    200: '#A8BEDC',
    300: '#7497C7',
    400: '#517DB9',
    500: '#426DA9',
    600: '#005687',
    700: '#004F71',
    800: '#003F5A',
    900: '#002839',
  },
  error: {
    25: '#FFFBFA',
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FECDCA',
    300: '#FDA29B',
    400: '#F97066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
    800: '#912018',
    900: '#7A271A',
  },
  warning: {
    25: '#FFFCF5',
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E',
  },
  success: {
    25: '#F6FEF9',
    50: '#ECFDF3',
    100: '#D1FADF',
    200: '#A6F4C5',
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    700: '#027A48',
    800: '#05603A',
    900: '#054F31',
  },
  moss: {
    50: '#ECEEEE',
    900: '#032127',
  },
};

export default colors;
