export interface HubSpotFieldFilters {
  boolValue: boolean;
  numberValue: number;
  operator: string;
  strValue: string;
  strValues: string[];
}

export interface HubSpotField {
  defaultValue: string;
  description: string;
  displayOrder: number;
  enabled: boolean;
  fieldType: string;
  groupName: string;
  hidden: boolean;
  isSmartField: boolean;
  label: string;
  labelHidden: boolean;
  name: string;
  objectTypeId: string;
  placeholder: string;
  propertyObjectType: string;
  required: boolean;
  selectedOptions: [];
  type: string;
  unselectedLabel: string;
  validation: { [key: string]: boolean };
  options: [];
  metaData: [];
  dependentFieldFilters: [{ dependentFormField: HubSpotField; filters: HubSpotFieldFilters[] }];
}

export const enableHoneypot = (form: any) => {
  if (form) {
    const btn = form.querySelector('.hs-button.primary.large');

    const honeypotIsTruthy = () => {
      const honeypot = form.querySelector('[name="honeypot"]');

      return !!honeypot?.value;
    };

    btn?.addEventListener('click', e => {
      if (honeypotIsTruthy()) {
        e.preventDefault();

        return false;
      }
    });
  }
};

export const validateEmail = (email: string) => {
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (email.match(validRegex)) {
    return true;
  }

  return false;
};

export const findField = (form: any, fieldName: string) => {
  for (const group of form.formFieldGroups) {
    for (const field of group.fields) {
      if (field.name === fieldName) {
        return field;
      }
      for (const { dependentFormField, filters } of field.dependentFieldFilters || []) {
        if (dependentFormField?.name === fieldName) {
          return { ...dependentFormField, filters: { ...filters, key: field.name } };
        }
      }
    }
  }

  return {};
};

export const getInitValues = (formData: any) => {
  const newData = formData?.formFieldGroups.reduce((groupsAcc: any, group: any) => {
    const groupData = group.fields.reduce(
      (fieldAcc: any, field: any) => {
        const { name, defaultValue, selectedOptions, dependentFieldFilters } = field;
        fieldAcc[name] = defaultValue || selectedOptions?.join(',') || null;

        dependentFieldFilters?.forEach(({ dependentFormField }: { dependentFormField: any }) => {
          const { name: dName, defaultValue: dValue, selectedOptions: dSelectedOptions } = dependentFormField;
          fieldAcc[dName] = dValue || dSelectedOptions?.join(',') || null;
        });

        return fieldAcc;
      },
      { ...groupsAcc },
    );

    return groupData;
  }, {});

  return newData;
};

export const isFieldVisible = (field: any, values: any) => {
  if (!field || field.hidden || field.isSmartField) {
    return false;
  }

  // Conditional Field
  if (!field.hidden && !field.filters) {
    return true;
  }

  const { filters } = field || {};
  const key = filters.key;
  const { strValue, strValues, operator } = filters[0] || {};

  if (!values[key]) {
    return false;
  }

  switch (operator) {
    case 'EQ':
      return values[key] && values[key] === strValue;
    case 'NEQ':
      return values[key] && values[key] !== strValue;
    case 'IS_NOT_EMPTY':
      return !!values[key];
    case 'CONTAINS':
      return values[key].includes(strValue);
    case 'DOESNT_CONTAIN':
      return !values[key].includes(strValue);
    case 'STR_STARTS_WITH':
      return values[key].startsWith(strValue);
    case 'STR_ENDS_WITH':
      return values[key].endsWith(strValue);
    case 'SET_ANY':
      return strValues.some(x => values[key].split(';').includes(x));
    case 'SET_NOT_ANY':
      return !strValues.some(x => values[key].split(';').includes(x));
    case 'SET_EQ':
      return strValues.every(x => values[key].split(';').includes(x));
    case 'SET_NOT_ALL':
      return !strValues.every(x => values[key].split(';').includes(x));
    default:
      return false;
  }
};

const getGTMData = () => {
  if (window !== undefined) {
    const gtmData = window.localStorage?.gtm_data;

    return gtmData ? JSON.parse(gtmData) : null;
  }
};

export const setGTMData = fieldsData => {
  const gtmData = getGTMData();
  if (gtmData) {
    for (const [key, value] of Object.entries(gtmData)) {
      const existingData = fieldsData.find(field => field.name === key);
      if (existingData) {
        existingData.value = value;
      } else {
        fieldsData.push({ name: key, value });
      }
    }
  }
};

export const getUserHutk = () =>
  // eslint-disable-next-line
  document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1');

export const prepareFormSubmissionValues = (form, values) =>
  Object.keys(values)
    .map(key => {
      const field = findField(form, key);
      const value = values[key];
      const isHidden = field.hidden;
      const isVisible = isFieldVisible(field, values);

      const formatValue = (val: any) =>
        val.includes(';') // ';' separated list values need to be passed as separate objects mapped to the same key e.g. Checkbox Group
          ? val.split(';').map(x => ({ name: key, value: x }))
          : { name: key, value: val };

      return value != null && (isHidden || isVisible) ? formatValue(value) : null;
    })
    .flat()
    .filter(x => x !== null);
