import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';

import type { FC } from 'react';

type LottieImageProps = {
  url: string;
  loop?: boolean;
};

const LottieImage: FC<LottieImageProps> = ({ url, loop = true }) => {
  const [animationData, setAnimationData] = useState('');
  const defaultOptions = {
    loop,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    if (url && url !== '') {
      fetch(url)
        .then(res => res.json())
        .then(out => setAnimationData(out));
    }
  }, []);

  return (
    <div className="[&>div]:cursor-auto">
      {animationData && <Lottie isClickToPauseDisabled options={{ ...defaultOptions, animationData }} />}
    </div>
  );
};

export default LottieImage;
