/* eslint-disable tailwindcss/no-arbitrary-value */
import { cva } from 'class-variance-authority';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import Checkbox from 'molecules/Checkbox';

import type { ChangeEventHandler, FC } from 'react';

const helper = cva(['text-gray-600', 'text-xs', 'mt-1'], {
  variants: {
    error: {
      true: ['text-error-700', 'font-semibold'],
    },
  },
});

interface CheckboxGroupProps {
  name?: string;
  className?: string;
  label?: string;
  labelHidden?: boolean;
  defaultValue?: string;
  helperText?: string | false;
  error?: boolean;
  errorMessage?: string;
  options: { label: string; value: string; description: string }[];
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onBlur?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
}

const CheckboxGroup: FC<CheckboxGroupProps> = ({
  name,
  className,
  label,
  labelHidden,
  options,
  helperText,
  error,
  errorMessage,
  value,
  defaultValue,
  onChange,
}) => {
  const [currValue, setCurrValue] = useState<string[]>(defaultValue?.split(';') || []);

  useEffect(() => {
    if (value) {
      setCurrValue(value.split(';'));
    }
  }, [value]);

  const handleChange = (selectedValue: string) => {
    const newValue = !currValue.includes(selectedValue)
      ? [...currValue, selectedValue]
      : currValue.filter(v => v !== selectedValue);
    setCurrValue(newValue);
    onChange && onChange({ target: { value: newValue.join(';'), name } });
  };

  return (
    <div className={twMerge('w-full mb-4', className)}>
      {!labelHidden && label && (
        <label htmlFor={name} className="text-sm font-semibold text-gray-700">
          {label}
        </label>
      )}
      <div className="input relative">
        {options.map((option, index) => (
          <Checkbox
            key={`checkbox-option-${index + 1}`}
            label={option.label}
            value={option.value}
            handleClick={handleChange}
            helperText={option?.description}
          />
        ))}
      </div>
      {(helperText || (error && value)) && (
        <p className={twMerge(helper({ error }))}>{error ? errorMessage || helperText : helperText}</p>
      )}
    </div>
  );
};
export default CheckboxGroup;
