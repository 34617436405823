import { useEffect, useState } from 'react';

import onKeyDown from 'utils/onKeyDown';

import type { ComponentPropsWithoutRef, FC } from 'react';

import 'molecules/Checkbox/styles.css';

export interface InputProps extends Omit<ComponentPropsWithoutRef<'input'>, 'size'> {
  label: string;
  name?: string;
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  helperText?: string;
  handleClick: (value: string) => void;
}

const Checkbox: FC<InputProps> = ({ label, name, disabled, checked, handleClick, helperText, ...props }) => {
  const [isChecked, setIsChecked] = useState(false);
  const CheckboxInput = 'div';

  const setActiveFunction = (value: string) => {
    setIsChecked(!isChecked);
    handleClick(value);
  };

  useEffect(() => {
    setIsChecked(checked || false);
  }, [checked]);

  return (
    <CheckboxInput
      className="flex w-fit cursor-pointer items-center gap-2 p-2 pl-0"
      onClick={() => setActiveFunction(label)}
      onKeyDown={e => onKeyDown(e, () => setActiveFunction(label))}
      tabIndex={0}
    >
      <div className={`relative size-4  ${helperText ? 'mb-auto' : '-mt-1'}`}>
        <input
          className="m-0 grid size-full cursor-pointer appearance-none place-content-center border border-gray-500 bg-none"
          type="checkbox"
          name={name}
          disabled={disabled}
          checked={isChecked}
          {...props}
        />
      </div>
      <div className="flex flex-col">
        {label && (
          <label htmlFor={name} className="cursor-pointer text-sm font-semibold text-gray-700">
            {label}
          </label>
        )}
        {helperText && <p className="text-xs text-gray-600">{helperText}</p>}
      </div>
    </CheckboxInput>
  );
};

export default Checkbox;
