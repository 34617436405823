import { cva } from 'class-variance-authority';

const modalStyles = cva(
  [
    'fixed',
    'inset-0',
    'w-screen',
    'h-screen',
    'backdrop-blur-md',
    'z-40',
    'bg-gray-900',
    'opacity-80',
    'cursor-default',
  ],
  {
    variants: {
      isOpen: {
        true: ['visible'],
        false: ['hidden'],
      },
      type: {
        form: ['hidden', 'md:visible'],
      },
    },
  },
);

export const modalWrapper = cva(
  [
    'fixed',
    'inset-0',
    'w-screen',
    'h-full',
    'z-50',
    'md:backdrop-blur-sm',
    'md:bg-black',
    'md:bg-opacity-50',
    'flex',
    'justify-center',
    'items-center',
    'overflow-y-scroll',
    'no-scrollbar',
  ],
  {
    variants: {
      isOpen: {
        true: ['visible'],
        false: ['hidden'],
      },
    },
  },
);

export const modalContainer = cva(
  ['relative', 'max-w-[100vw]', 'md:max-w-[80vw]', 'md:max-h-[80vh]', 'md:max-w-xl', 'h-full', 'md:h-auto'],
  {
    variants: {
      type: {
        embed: ['h-auto'],
        form: ['md:max-h-screen', 'md:mt-[80px]'],
      },
    },
  },
);

export const modalColumn = cva(
  [
    'flex',
    'flex-col',
    'max-w-[100vw]',
    'h-full',
    'md:max-w-[80vw]',
    'md:max-h-[80vh]',
    'h-full',
    'overflow-auto',
    'relative',
  ],
  {
    variants: {
      type: {
        embed: ['max-h-[80vh] w-[50rem] max-w-[80vw]'],
        form: ['w-[640px]', 'md:max-h-full', 'md:overflow-hidden'],
      },
      isSignInModal: {
        true: ['overflow-visible', 'h-auto'],
      },
    },
  },
);

export const closeModalButton = cva(['fill-white', 'self-end', 'absolute', 'w-fit', 'z-[100]']);

export const modalVideoEmbed = cva(['aspect-video', 'w-full']);

export const modalContentRow = cva(['flex'], {
  variants: {
    type: {
      container: ['flex-col', 'lg:flex-row', 'pt-8', 'justify-between', 'gap-12'],
      content: ['flex-col', 'max-w-fit', 'gap-4'],
      cta: ['flex-col', 'min-w-max'],
    },
  },
});

export const modalContent = cva([], {
  variants: {
    type: {
      heading: [
        'text-white',
        'text-display-xs-mobile',
        'sm:text-display-xs-tablet',
        'md:text-display-xs',
        'font-extrabold',
      ],
      subhead: ['text-moss-50', 'text-md', 'font-normal'],
    },
  },
});

export default modalStyles;
