/* eslint-disable no-inline-styles/no-inline-styles */
import type { FC } from 'react';

interface HubspotVideoProps {
  playerId: string;
}

const HubspotVideo: FC<HubspotVideoProps> = ({ playerId }) => {
  if (!playerId) {
    return null;
  }

  return (
    <iframe
      referrerPolicy="origin"
      sandbox="allow-forms allow-scripts allow-same-origin allow-popups"
      allow="autoplay; fullscreen;"
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
        pointerEvents: 'initial',
        aspectRatio: '16 / 9',
      }}
      title="HubSpot Video"
      loading="lazy"
      data-hsv-id={playerId}
      data-hsv-style=""
      data-hsv-width="640"
      data-hsv-height="360"
      src={`https://play.hubspotvideo.com/v/3381425/id/${playerId}?parentOrigin=https%3A%2F%2Fwww.youraspire.com&amp;renderContext=hubl-iframe`}
    ></iframe>
  );
};

export default HubspotVideo;
