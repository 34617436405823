import Button from 'molecules/Button';
import CheckboxGroup from 'molecules/CheckboxGroup';
import RadioGroup from 'molecules/RadioGroup';
import Select from 'molecules/Select';
import TextField from 'molecules/TextField';

import { isFieldVisible } from 'components/Form/utils/functions';

import type { HubSpotField } from 'components/Form/utils/functions';
import type { SyntheticEvent } from 'react';

interface HubspotFormFieldsProps {
  values: { [key: string]: any };
  validation: { [key: string]: boolean };
  fieldGroup: HubSpotField[];
  handleBlur(event: SyntheticEvent): any;
  handleChange(event: SyntheticEvent): any;
  isSubscribe?: boolean;
}

export const HubspotFormFields = ({
  values,
  validation,
  fieldGroup,
  handleBlur,
  handleChange,
  isSubscribe,
}: HubspotFormFieldsProps) => {
  const removeHiddenFields = (fields: HubSpotField[]) =>
    fields.filter((field: HubSpotField) => {
      const { hidden, filters, isSmartField } = field || {};

      return !(isSmartField || hidden || (!!filters && !isFieldVisible(field, values)));
    });

  const fields = removeHiddenFields(fieldGroup);

  return (
    fields.length > 0 && (
      <div className="fieldgroup flex w-full flex-col gap-0 @lg:flex-row @lg:gap-4" data-testid="hs-formfield-group">
        {fields.map((field: HubSpotField, index: number) => {
          const {
            name,
            label,
            labelHidden,
            type,
            fieldType,
            placeholder,
            required,
            description,
            validation: hs_validation,
          } = field || {};

          if (type === 'string' || type === 'number') {
            return (
              <TextField
                key={index}
                value={values[name]}
                placeholder={`${placeholder}${!label && required ? '*' : ''}`}
                required={required}
                name={name}
                label={!labelHidden && label ? `${label}${required ? '*' : ''}` : ''}
                type={fieldType === 'phonenumber' ? 'tel' : fieldType}
                onChange={handleChange}
                onBlur={handleBlur}
                error={validation[name]}
                helperText={description}
                errorMessage={hs_validation?.message || (label ? `${label} is required` : placeholder)}
                {...(isSubscribe && validation[name] && { endIcon: 'alert-circle' })}
              />
            );
          }
          if (fieldType === 'select') {
            const { options, unselectedLabel } = field || {};

            return (
              <Select
                key={index}
                value={values[name]}
                placeholder={placeholder}
                required={required}
                name={name}
                label={!labelHidden && label ? `${label}${required ? '*' : ''}` : ''}
                unselectedLabel={unselectedLabel}
                options={options}
                onChange={handleBlur} // onBlur has validation check
                error={validation[name]}
                errorMessage={hs_validation?.message || 'Please select an option.'}
                helperText={description}
              />
            );
          }
          if (fieldType === 'radio' || fieldType === 'booleancheckbox') {
            const { options } = field || {};

            return (
              <RadioGroup
                key={index}
                value={values[name]}
                name={name}
                label={!labelHidden && label ? `${label}${required ? '*' : ''}` : ''}
                options={options}
                onChange={handleBlur} // onBlur has validation check
                error={validation[name]}
                errorMessage={hs_validation?.message || 'Please select an option.'}
                helperText={description}
              />
            );
          }

          if (fieldType === 'checkbox') {
            const { options } = field || {};

            return (
              <CheckboxGroup
                key={index}
                value={values[name]}
                name={name}
                label={!labelHidden && label ? `${label}${required ? '*' : ''}` : ''}
                options={options}
                onChange={handleChange}
                onBlur={handleBlur}
                error={validation[name]}
                errorMessage={hs_validation?.message || 'Please select an option.'}
                helperText={description}
              />
            );
          }

          return null;
        })}
      </div>
    )
  );
};

interface PrivacyPolicyProps {
  privacyPolicyHTML: string;
}

export const PrivacyPolicy = ({ privacyPolicyHTML = '' }: PrivacyPolicyProps) =>
  privacyPolicyHTML && (
    <div
      className="legal-consent-container text-sm"
      dangerouslySetInnerHTML={{
        __html: privacyPolicyHTML,
      }}
    ></div>
  );

interface SubmitProps {
  handleSubmit(): any;
  label: string;
}

export const Submit = ({ handleSubmit, label = '' }: SubmitProps) => (
  <div className="hs_submit mb-4 flex w-full flex-col" onClick={handleSubmit} role="presentation">
    <Button label={label} isFullWidth as="button" />
  </div>
);
