import { Link } from 'gatsby';

export const DOMAINS = ['www.youraspire.com', 'youraspire.com', 'aspire-preview.netlify.app'];

const parseUrl = (href: string) => {
  if (!href) {
    return {
      Component: 'div',
      to: '',
    } as const;
  }

  if (href.startsWith('#')) {
    return {
      Component: 'a',
      href,
    };
  }

  const link = href[0] === '/' ? `https://www.youraspire.com${href}` : href;
  try {
    const url = new URL(link);
    const isInternal = DOMAINS.includes(url.hostname);

    return {
      Component: isInternal ? Link : 'a',
      rel: isInternal ? '' : 'noreferrer noopener',
      target: isInternal ? '' : '_blank',
      href: isInternal ? url.href.split(url.host)[1] : link,
    };
  } catch (error) {
    return {
      Component: 'div',
    } as const;
  }
};

export default parseUrl;
