import { useEffect } from 'react';

import type { FC } from 'react';
import 'components/Form/chilipiper.css';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ChiliPiper: any;
  }
}

interface ChiliPiperWidgetProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any;
  cpRouterName: string;
  onError?: () => void;
}

const ChiliPiperWidget: FC<ChiliPiperWidgetProps> = ({ values, cpRouterName, onError }) => {
  const cpTenantDomain = 'www-youraspire'; // replace with your subdomain

  useEffect(() => {
    window.ChiliPiper.submit(cpTenantDomain, cpRouterName, {
      domElement: '#chili-piper-container',
      map: true,
      lead: values,
      onError: () => {
        onError && onError();
      },
    });
  }, []);

  return <div id="chili-piper-container" />;
};

export default ChiliPiperWidget;
