import type { ComponentPropsWithoutRef, FC, SyntheticEvent } from 'react';

import 'molecules/Radio/styles.css';

export interface InputProps extends Omit<ComponentPropsWithoutRef<'input'>, 'size'> {
  label: string;
  name?: string;
  value: string;
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  helperText?: string;
  handleClick: (e: SyntheticEvent) => void;
}

const Radio: FC<InputProps> = ({ label, name, value, disabled, checked, handleClick, helperText, ...props }) => {
  const RadioInput = 'div';

  return (
    <RadioInput className="flex size-fit flex-wrap items-center gap-2 p-0" tabIndex={0}>
      <div className="relative size-4">
        <input
          className="m-0 grid size-full cursor-pointer appearance-none place-content-center border border-gray-500 bg-none"
          type="radio"
          id={`${name}-${label}`}
          name={name}
          disabled={disabled}
          checked={checked}
          value={value}
          onClick={handleClick}
          {...props}
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor={`${name}-${label}`} className="text-sm font-semibold text-gray-700">
          {label}
        </label>

        {helperText && <p className="text-xs text-gray-600">{helperText}</p>}
      </div>
    </RadioInput>
  );
};

export default Radio;
